<script>
import { http } from '~vue/helpers/http';
import storage from 'utils/storage';
import { signinCallbackCtor } from '../dependencies/signin-callback'

export default {
  async beforeRouteEnter(to, from, next) {
    const signinCallback = signinCallbackCtor(http, storage, window);

    await signinCallback();
  },
};
</script>
