<template>
  <div class="input-password inline-button">
    <input
      id="password"
      v-model="password"
      v-focus="focus"
      :disabled="disabled"
      :required="required"
      :class="{ 'invalid' : error }"
      :minlength="minlength"
      :type="isPasswordVisible ? 'text' : 'password'"
      @focus="$emit('focus')"
      @blur="$emit('blur')"
    >
    <button type="button" class="small subtle" :disabled="disabled" @click="toggle" >{{ label }}</button>
  </div>
</template>
<script>
// Todo: Remove v-html usage in <button> after switching from Transifex

import InputFocus from '~vue/directives/input-focus';

export default {
  directives: {
    focus: InputFocus,
  },

  props: {
    minlength: {
      type: Number,
      default: 0,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: true,
    },
    focus: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isPasswordVisible: false,
      password: '',
    };
  },

  computed: {
    label() {
      return this.isPasswordVisible ? this.$t('Hide') : this.$t('Show');
    },
  },

  watch: {
    password(value) {
      this.$emit('input', value);
    },
  },

  methods: {
    toggle() {
      this.isPasswordVisible = !this.isPasswordVisible;
    },
  },
};
</script>
