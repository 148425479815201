<template>
  <div class="notranslate">
    <p class="referral-agreement">
      <small>
        <span>Please read this</span>
        <a href="/referral-agreement-horizons" target="_blank">referral disclosure statement</a>
        <span>carefully. Horizons is referring you, as a potential client, to CI Direct Investing pursuant to a referral arrangement between CI Direct Investing and Horizons.</span>
      </small>
    </p>
    <p class="referral-agreement">
      <small>
        <span>In opening a CI Direct Investing account, you will pay management fees to CI Direct Investing and will be placed into a model portfolio that may contain investment funds managed by Horizons. Please review the</span>
        <a href="https://help.cidirectinvesting.com/10762-general-questions/client-relationship-disclosure-information" target="_blank">Relationship Disclosure Information (RDI)</a>
        <span>of CI Direct Investing.</span>
      </small>
    </p>
    <div class="field-checkbox">
      <input id="agreement" v-model="agreedToAgreement" @change="setAgreed" type="checkbox" required/>
      <label for="agreement"><strong>By selecting “Agree and Continue” below, you acknowledge and agree to the information contained in the Referral Disclosure and the RDI and wish to proceed to opening an account with CI Direct Investing.</strong></label>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      agreedToAgreement: false,
    };
  },
  methods: {
    setAgreed() {
      if (this.agreedToAgreement) {
        this.$emit('setAgreed', true);
      } else {
        this.$emit('setAgreed', false);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .referral-agreement {
    margin-top: 1rem;
  }
</style>
