export const oktaI18n = {
  en: {
    'primaryauth.title': 'Sign In',
    'primaryauth.username.placeholder': 'Email',
    'primaryauth.username.tooltip': 'Email',
    'primaryauth.submit': 'Sign in',
    'password.forgot.email.or.username.placeholder': 'Email',
    'password.forgot.email.or.username.tooltip': 'Email',
    // eslint-disable-next-line quote-props
    'remember': 'Remember my email',
  },
  fr: {
    'primaryauth.title': 'Connexion',
    'primaryauth.username.placeholder': 'Courriel',
    'primaryauth.username.tooltip': 'Courriel',
    'primaryauth.submit': 'Connexion',
    'password.forgot.email.or.username.placeholder': 'Courriel',
    'password.forgot.email.or.username.tooltip': 'Courriel',
    'password.forgot.sendEmail': 'Réinitialiser par Courriel',
    'password.forgot.emailSent.title': 'Courriel envoyé!',
    'password.forgot.emailSent.desc': 'Un courriel a été envoyé à {0} avec des instructions pour réinitialiser votre mot de passe.',
    // eslint-disable-next-line quote-props
    'remember': 'Mémoriser mon courriel',
  },
};
