<template>
  <div class="signup-page notranslate">
    <banner :link="nav"></banner>
    <main id="signup-content">
      <div class="container">
        <div class="signup">
          <div class="copy">
            <h2 v-t>Let's grow your money.</h2>
            <p v-t>We’ll find a portfolio that’s right for you. Getting started is fast and easy!</p>
          </div>
          <signup-form @auth-redirect="authRedirect" :partnerChannel="this.partnerChannel" ></signup-form>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import Banner from '@layoutTheme/banner';
import { http } from '~vue/helpers/http';
import SignupForm from '~vue/components/forms/form-signup';

export default {
  components: {
    Banner,
    SignupForm,
  },
  created() {
    this.redirectWhenLoggedIn();
    this.partnerChannel = this.$route.query?.pc ? this.$route.query.pc : '';
  },
  methods: {
    async redirectWhenLoggedIn() {
      try {
        await http.get('/api/sessions');
        window.location = '/signin/continue';
      // user is not logged in - catching to silence error
      // eslint-disable-next-line no-empty
      } catch (error) {}
    },
    authRedirect() {
      window.location = '/signup/continue';
    },
  },
  computed: {
    nav() {
      return {
        title: this.$t('Sign In'),
        url: '/signin',
      };
    },
  },
  data: {
    partnerChannel: '',
  }
};
</script>

<style lang="scss" scoped>
  .signup-page {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }

  #signup-content {
    display: flex;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
    .container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .signup {
      max-width: 20rem;
    }

    .copy { text-align: center; }
  }
  .language-hide { display: none; }
</style>
