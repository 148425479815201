import { setLoginData } from '~vue/apps/auth/dependencies/handle-signin';
import { http } from '~vue/helpers/http';

export async function showSignInToGetTokens(
  oktaInstance,
  oktaContainerObj,
  postAuthAndGetRedirectUrl,
  httpPost = http.post,
  applyLoginData = setLoginData,
  win = window,
  log = console.log,
) {
  try {
    const { accessToken: { accessToken } } = await oktaInstance.showSignInToGetTokens(oktaContainerObj);
    const { data } = await httpPost('/api/sessions/token', {
      access_token: accessToken,
    });
    applyLoginData(data);
    const redirectUrl = await postAuthAndGetRedirectUrl();

    win.location = redirectUrl;
  } catch (error) {
    log('Okta login error :>> ', error);
  }
}
