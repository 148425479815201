<template>
  <div class="page notranslate">
    <banner />
    <main class="content">
      <div class="key"></div>
      <h2 v-t class="title">Enter new password</h2>
      <p v-t class="instructions">Enter your new password and you're good to go.</p>
      <vue-form :state="formstate" class="form" @submit.prevent="sendPassword">
        <validate :custom="{ isPasswordValid }">
          <label v-t for="password">New password</label>
          <input id="password" v-model="password" type="password" name="password" minlength="8" required />

          <field-messages name="password" show="$touched || $submitted" class="error-message">
            <span slot="required">{{ $t('Required') }}</span>
            <span slot="isPasswordValid">{{ $t('Please ensure the following requirements are met:') }}</span>
          </field-messages>
        </validate>

        <div v-if="password" class="password-validation">
          <ul>
            <li v-t :class="{ 'valid': passwordErrors.minLength }">At least 8 characters</li>
            <li v-t :class="{ 'valid': passwordErrors.number }">At least 1 number</li>
            <li v-t :class="{ 'valid': passwordErrors.symbol }">At least 1 symbol</li>
            <li v-t :class="{ 'valid': passwordErrors.lowerCase }">At least 1 lowercase letter</li>
            <li v-t :class="{ 'valid': passwordErrors.upperCase }">At least 1 uppercase letter</li>
            <li v-t :class="{ 'valid': passwordErrors.email }">Does not contain part of the email</li>
            <li v-if="user.firstname" v-t :class="{ 'valid': passwordErrors.firstName }">Does not contain 'First Name'</li>
            <li v-if="user.lastname" v-t :class="{ 'valid': passwordErrors.lastName }">Does not contain 'Last Name'</li>
          </ul>
        </div>

        <button v-if="state !== 'loading'" type="submit" class="button" v-t>Reset password</button>
        <div v-if="state === 'loading'" class="loader">
          <loading-spinner size="15px" />
          <span v-t>Submitting...</span>
        </div>

        <transition name="fade">
          <p v-if="errorMessage" class="error-message">{{errorMessage}}</p>
        </transition>
      </vue-form>
    </main>
  </div>
</template>

<script>
import VueForm from 'vue-form';
import Banner from '@layoutTheme/banner';
import { http } from '~vue/helpers/http';
import { isValidPassword, getPasswordValidity } from '~vue/helpers/check-password-validity';
import LoadingSpinner from '~vue/components/loading-spinner';

export default {
  components: {
    LoadingSpinner,
    Banner,
  },
  mixins: [VueForm],
  data() {
    return {
      formstate: {},
      password: null,
      state: null,
      errorMessage: null,
      user: {
        email: '',
        firstname: '',
        lastname: '',
      },
      passwordErrors: {
        minLength: false,
        number: false,
        symbol: false,
        lowerCase: false,
        upperCase: false,
        email: false,
        firstName: false,
        lastName: false,
      },
    };
  },
  computed: {
    isPasswordValid() {
      return isValidPassword(this.password || '', this.user.email || '', this.user.firstname || '', this.user.lastname || '');
    },
  },
  watch: {
    password(value) {
      this.passwordErrors = getPasswordValidity(value || '', this.user.email || '', this.user.firstname || '', this.user.lastname || '');
    },
  },
  async created() {
    try {
      const results = await http.post('/api/users/password/details', { reset_password_token: this.$route.query.token });
      this.user = results.data;
    } catch ({ response }) {
      if (response.status === 404) {
        this.$router.push({ path: '/reset-password/invalid-email-reset-token' });
      } else {
        this.errorMessage = response.data.message;
      }
    }
  },
  methods: {
    async sendPassword() {
      if (this.formstate.$invalid || this.state === 'loading') return;
      if (this.errorMessage) this.errorMessage = null;

      this.state = 'loading';
      try {
        await http.put(
          '/api/users/password',
          {
            reset_password_token: this.$route.query.token,
            password: this.password,
          },
        );
        window.location = '/app/';
      } catch (error) {
        this.errorMessage = error.response.data.message;
        this.state = null;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .page {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 100vh;
    align-items: center;
  }

  .content {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    top: 0;
    position: relative;
    width: 85%;
    max-width: 20rem;
    min-height: 40rem;
    margin-bottom: 3rem;
    text-align: center;

    > * {
      width: 100%;
    }
  }

  .key {
    background-image: url('../images/reset-icon.svg');
    background-repeat: no-repeat;
    background-size: cover;
    width: 3rem;
    height: 3rem;
  }

  .title {
    margin-top: 1.1rem;
  }

  .instructions {
    max-width: 18rem;
  }

  .form {
    display: flex;
    flex-direction: column;
    text-align: left;

    .field { width: 100%; }

    .button {
      width: 100%;
      margin-top: 1rem;
    }
  }

  .loader {
    padding: 0.55rem 0;
    text-align: center;
  }

  .password-validation {
    margin-top: -0.75rem;
    ul {
      li {
        line-height: 1.5rem;
        position: relative;
        padding-left: 1.5rem;
        color: $neg;
        font-size: 0.875rem;
        &:before {
          content: '';
          position: absolute;
          left: 0;
          top:0.35rem;
          height: 1rem;
          width: 1rem;
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' fill='%23"+ str-slice(#{$neg}, 2) + "'%3E%3Cpath d='M16,4c6.6,0,12,5.4,12,12s-5.4,12-12,12S4,22.6,4,16S9.4,4,16,4 M16,2C8.3,2,2,8.3,2,16s6.3,14,14,14s14-6.3,14-14 S23.7,2,16,2L16,2z'/%3E%3Crect x='15' y='7' width='2' height='12'/%3E%3Ccircle cx='16' cy='22.5' r='1.5'/%3E%3C/svg%3E");
        }
        &.valid {
          color: $neutral-700;
          &:before {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cpolygon points='11,27.4 1.3,17.7 2.7,16.3 11,24.6 29.3,6.3 30.7,7.7'/%3E%3C/svg%3E");
          }
        }
      }
    }
  }

  .fade-enter-active, .fade-leave-active {
    transition: opacity 500ms;
  }

  .fade-enter, .fade-leave-to {
    opacity: 0
  }
</style>
