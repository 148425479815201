import Vue from 'vue';
import VueRouter from 'vue-router';
import VueCookie from 'vue-cookie';
import { i18nMixin, registerLanguages } from '@wealthbar/i18n';
import changeLanguage from '@wealthbar/i18n/dist/change_language';

import locale from '~utils/locale';
import processLabels from '~utils/process-labels';
import { getSignupUrl } from '~utils/external_links';

import Auth from './auth.vue';

import SignupError from './pages/error/signup-error.vue';
import SignupEmailExists from './pages/error/signup-email-exists.vue';
import SignupPage from './pages/signup.vue';
import CachefloSignupPage from './pages/cacheflo-signup.vue'
import McelvaineSignupPage from './pages/mcelvaine-signup.vue';
import HorizonsSignupPage from './pages/horizons-signup.vue';
import NWMSignupPage from './pages/nwm-signup.vue';
import InfinitySignupPage from './pages/infinity-signup.vue';
import WealthieSignupPage from './pages/wealthie-signup.vue';
import SigninError from './pages/error/signin-error.vue';
import SigninPage from './pages/signin.vue';
import SigninCallback from './pages/signin-callback.vue';
import TwoFactor from './pages/two-factor.vue';

import LoggedOut from './pages/logged-out.vue';

import SendEmail from './pages/send-email.vue';
import CheckEmail from './pages/check-email.vue';
import NewPassword from './pages/new-password.vue';
import InvalidEmailResetToken from './pages/invalid-email-reset-token.vue';

import RepresentativeSignup from './pages/representative-invitation/signup.vue';
import RepresentativeSignin from './pages/representative-invitation/signin.vue';
import RepresentativeInvalid from './pages/representative-invitation/invalid.vue';
import RepresentativeReferral from './pages/representative-invitation/referral.vue';

Vue.use(VueRouter);
Vue.use(VueCookie);

Vue.directive("focus", {
  inserted: function(el, binding) {
    if (binding.value) {
      el.focus();
    }
  },
});

Vue.mixin(i18nMixin);

function beforeEnterSignup(to, from, next) {
  if (window.process && window.process.env && window.process.env.FEATURE_LEGACY_SIGNUP === 'true') return next();

  window.location = getSignupUrl(locale.getLanguage());
  return false;
}

const router = new VueRouter({
  mode: 'history',
  routes: [
    { path: '/signup', component: SignupPage, beforeEnter: beforeEnterSignup },
    { path: '/signup/error', component: SignupError },
    { path: '/signup/email-exists', component: SignupEmailExists },
    // { path: '/signup/cacheflo', component: CachefloSignupPage },
    // { path: '/signup/3p', component: McelvaineSignupPage },
    { path: '/signup/horizons', component: HorizonsSignupPage },
    // { path: '/signup/nicolawealth', component: NWMSignupPage },
    // { path: '/signup/infinity', component: InfinitySignupPage },
    // { path: '/signup/wealthie', component: WealthieSignupPage },
    { path: '/signin', component: SigninPage },
    { path: '/signin/error', component: SigninError },
    { path: '/signin/sso/callback', component: SigninCallback },
    { path: '/two-factor', component: TwoFactor },
    { path: '/logged-out', component: LoggedOut },
    { path: '/reset-password', component: SendEmail },
    { path: '/reset-password/check-email', component: CheckEmail },
    { path: '/reset-password/new-password', component: NewPassword },
    { path: '/reset-password/invalid-email-reset-token', component: InvalidEmailResetToken },
    { path: '/representative-invitation',component: RepresentativeSignup },
    { path: '/representative-invitation/signin', component: RepresentativeSignin },
    { path: '/representative-invitation/invalid', component: RepresentativeInvalid },
    { path: '/representative-invitation/:slug', component: RepresentativeReferral },
    { path: '/*', redirect: '/' },
  ],
});

registerLanguages({
  'fr-CA': async () => {
    const lang = await import('~locale/fr_CA/wealthbar-app.po');
    return processLabels(lang);
  },
});

const language = locale.isFrench() ? 'fr-CA' : 'en';

changeLanguage(language).then(() => {
  return new Vue({
    el: '#app',
    router,
    render(h) { return h(Auth); },
  });
});
