/*
  This is a temporary fix for processing po files,
  the webpack plugin po-loader that retrieves the po files does not convert labels into JSON correctly.

  I switched to "raw" but the file still needs extra processing to get a map of labels.

  Longer term we will need to write our own plugin.
*/
export default function processLabels(poLabels) {
  const labels = {};
  Object.keys(poLabels.default).forEach(key => { labels[key] = poLabels.default[key][1]; });
  return labels;
}
