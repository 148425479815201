<template>
  <div class="signup-page">
    <banner :link="nav"></banner>
    <main id="signup-content" class="notranslate">
      <div class="content-container">
        <div class="partner-promo">
          <div class="center-container">
            <img
              src="/partner-channels/3p-financial/3p-financial-logo.jpg"
              :alt="$t('3P Financial logo')"
              class="partner-logo"
            />
            <div v-t class="partner-copywriting">Sign up to invest in 3P Financial portfolios. 3P is a division of McElvaine Investment Management Ltd.</div>
          </div>
        </div>
        <div class="container">
          <div class="signup">
            <div class="copy">
              <h2 v-t>Let's grow your money.</h2>
              <p v-t>We’ll find a portfolio that’s right for you. Getting started is fast and easy!</p>
            </div>
            <signup-form @auth-redirect="authRedirect" partner-channel="3p-financial" signup-button="Agree and Continue"></signup-form>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import Banner from '@layoutTheme/banner';
import { http } from '~vue/helpers/http';
import SignupForm from '~vue/components/forms/form-signup';

export default {
  components: {
    Banner,
    SignupForm,
  },
  data() {
    return {
      nav: {
        title: this.$t('Sign In'),
        url: '/signin',
      },
    };
  },
  created() {
    this.redirectWhenLoggedIn();
  },
  methods: {
    async redirectWhenLoggedIn() {
      try {
        await http.get('/api/sessions');
        window.location = '/signin/continue';
      // user is not logged in - catching to silence error
      // eslint-disable-next-line no-empty
      } catch (error) {}
    },
    authRedirect() {
      window.location = '/signup/continue';
    },
  },
};
</script>

<style lang="scss" scoped>
  .signup-page {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }

  #signup-content {
    display: flex;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
    padding-bottom: 6rem;

    @media #{$screen-width-medium} { padding-bottom: 1.5rem; }

    .partner-promo {
      padding: 1.5rem 1.5rem 0 1.5rem;
      margin-bottom: 1.5rem;
      flex-grow: 0;
      display: flex;
      justify-content: center;

      @media #{$screen-width-medium} { margin-bottom: 0; }
    }

    .content-container { width: 100%; }

    .center-container {
      width: 20rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-bottom: 1px solid $neutral-100;

      @media #{$screen-width-medium} {
        width: 40rem;
        flex-direction: row;
        padding-bottom: 1.5rem;
      }
    }

    .partner-logo {
      width: 7rem;
      margin: 0 0 1.5rem 0;

      @media #{$screen-width-medium} { margin: 0 2rem 0 0; }
    }

    .partner-copywriting {
      text-align: center;
      margin: 0 0 1.5rem 0;

      @media #{$screen-width-medium} { margin: 0; }
    }

    .container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .signup {
      max-width: 20rem;
    }

    .copy { text-align: center; }
  }
  .language-hide { display: none; }
</style>
