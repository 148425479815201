<template>
  <div class="page notranslate">
    <banner nav-public></banner>
    <article class="container">
      <img
        src="@theme/images/error/alert.png"
        :alt="$t('error icon')"
      />
      <h3 v-t>Something went wrong.</h3>
      <slot></slot>
    </article>
    <footer-component @language-changed="languageChanged"></footer-component>
  </div>
</template>

<script>
import Banner from '@layoutTheme/banner';
import Footer from '@layoutTheme/footer';
import locale from 'utils/locale';

export default {
  components: {
    Banner,
    'footer-component': Footer,
  },
  created() {
    this.language = locale.getLanguage();

  },
  data() {
    return {
      language: null,
    };
  },
  methods: {
    languageChanged(newLanguage) {
      this.language = newLanguage;
    },
  },
};
</script>

<style lang="scss" scoped>
  .page {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .container {
    text-align: center;
    padding-top: 12rem;
    padding-bottom: 12rem;

    p {
      margin: 0 auto;
      max-width: 32rem;
    }
  }
</style>

