<template>
  <div class="page">
    <banner />
    <main class="content" :class="{'okta': oktaUser}">
      <div class="key"></div>
      <div v-if="!oktaUser">
        <h2 class="title">{{ $t('Reset your password') }}</h2>
        <p v-t>We'll email you instructions to reset your password.</p>
        <reset-password-email-form @reset-password="$emit('reset-password', $event)" @okta-user="setOktaUser()" />
      </div>
      <div v-else>
        <h2 class="title">{{ $t('We\'re unable to reset your password on this web site') }}</h2>
        <p>
          <span v-t>We've moved! Visit our</span>
          <a v-t :href="loginUrl">new site</a>
          <span v-t>and select "Forgot password?" to reset your password.</span>
        </p>
        <div class="signin-block"><a v-t href="/signin" class="return-to-signin">Back to sign in</a></div>
      </div>
    </main>
  </div>
</template>

<script>
import Banner from '@layoutTheme/banner';
import ResetPasswordEmailForm from '~vue/components/forms/form-reset-password-email';
import { CommonDashboardSigninUrls } from '~vue/config/common-dashboard-web';

export default {
  components: {
    ResetPasswordEmailForm,
    Banner,
  },
  data() {
    return {
      oktaUser: false,
    };
  },
  computed: {
    loginUrl() {
      return this.$lang.slice(0, 2) === 'fr' ? CommonDashboardSigninUrls.fr : CommonDashboardSigninUrls.en;
    },
  },
  methods: {
    setOktaUser() {
      this.oktaUser = true;
    },
  },
};
</script>

<style lang="scss" scoped>
  .page {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 100vh;
    align-items: center;
  }

  .content {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    top: 0;
    position: relative;
    width: 85%;
    max-width: 20rem;
    min-height: 40rem;
    margin-bottom: 3rem;
    text-align: center;

    &.okta {
      max-width: 25rem;
      width: 100%;
    }

    > * {
      width: 100%;
    }
  }

  .key {
    background-image: url('../images/reset-icon.svg');
    background-repeat: no-repeat;
    background-size: cover;
    width: 3rem;
    height: 3rem;
  }

  .title {
    margin-top: 1.1rem;
  }

  .signin-block {
    margin-top: 1.75rem;
    text-align: center;

    .return-to-signin {
      color: #777;
      text-decoration: none;
      font-size: 0.8125rem;
      &:hover, &:active {
        text-decoration: underline;
      }
    }
  }
</style>
