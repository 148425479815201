<template>
  <vue-form :state="formstate" class="form notranslate" @submit.prevent="resetPassword">
    <validate auto-label>
      <label v-t for="email">Email</label>
      <input id="email" v-model="model.email" type="email" name="email" required />

      <field-messages name="email" show="$touched || $submitted" class="error-message">
        <span slot="email">{{ $t('Invalid email') }}</span>
        <span slot="required">{{ $t('Required') }}</span>
      </field-messages>
    </validate>

    <button v-if="state !== 'loading'" v-t type="submit" class="button">Submit</button>
    <div v-if="state === 'loading'" class="loader">
      <loading-spinner size="15px" />
      <span v-t>Submitting...</span>
    </div>

    <div class="signin-block"><a v-t href="/signin" class="return-to-signin">Back to sign in</a></div>

    <transition name="fade">
      <p v-if="errorMessage" class="error post-error">{{ errorMessage }}</p>
    </transition>
  </vue-form>
</template>

<script>
import VueForm from 'vue-form';
import { http } from '~vue/helpers/http';
import LoadingSpinner from '../loading-spinner';

export default {
  components: {
    LoadingSpinner,
  },
  mixins: [VueForm],
  data() {
    return {
      formstate: {},
      model: {
        email: '',
      },
      state: null,
      errorMessage: null,
    };
  },
  methods: {
    async resetPassword() {
      if (this.formstate.$invalid || this.state === 'loading') return;
      if (this.errorMessage) this.errorMessage = null;

      this.state = 'loading';

      try {
        await http.post('/api/users/password', { email: this.model.email });
        this.$emit('reset-password', this.model.email);
      } catch ({ response }) {
        this.state = null;

        if ((response.status === 400 || response.status === 500) && !response.data.message) {
          this.$emit('okta-user');
        } else {
          this.errorMessage = response.data.message;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .form {
    display: flex;
    flex-direction: column;
    text-align: left;

    .field {
      margin-bottom: 1rem;
      width: 100%;
    }

    .button { width: 100%; }
  }

  .loader {
    padding: 0.5rem 0;
    text-align: center;
  }

  .signin-block {
    margin-top: 1.75rem;
    text-align: center;

    .return-to-signin {
      color: #777;
      text-decoration: none;
      font-size: 0.8125rem;
      &:hover, &:active {
        text-decoration: underline;
      }
    }
  }

  .fade-enter-active, .fade-leave-active {
    transition: opacity 500ms;
  }

  .fade-enter, .fade-leave-to {
    opacity: 0
  }
</style>
