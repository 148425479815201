<template>
  <div class="sign-in-widget">
    <div id="okta-signin-container"></div>
    <div class="forgot-password">
      <a v-t href="/reset-password">Forgot password?</a>
    </div>
    <transition name="fade">
      <p v-if="errorMessage" class="post-error">{{ errorMessage }}</p>
    </transition>
  </div>
</template>

<script>

import { getOktaSignIn } from '~vue/apps/auth/dependencies/okta-instance';
import { showSignInToGetTokens } from '~vue/apps/auth/dependencies/okta-show-signin-to-get-tokens';
import { handleCreds } from '~vue/apps/auth/dependencies/handle-signin';
import { oktaI18n } from '~vue/config/okta-i18n';

export default {
  name: 'SignInWidget',
  props: {
    title: {
      type: Object,
      // eslint-disable-next-line arrow-body-style
      default: () => {
        return { en: 'Sign In', fr: 'Connexion' };
      },
    },
    postAuthAndGetRedirectUrl: {
      type: Function,
      default: () => '/signin/continue',
    },
  },
  data() {
    return {
      oktaSignIn: null,
      errorMessage: null,
    };
  },
  watch: {
    async $lang(lang) {
      this.errorMessage = null;

      const currentLang = lang.slice(0, 2) === 'fr' ? 'fr' : 'en';
      if (this.oktaSignIn) {
        this.oktaSignIn.remove();
      }
      await this.startOkta(currentLang);
      const showSignInToGetTokensCallback = await this.showSignInToGetTokens(this.oktaSignIn);
      this.$nextTick(await showSignInToGetTokensCallback);
    },
  },
  async mounted() {
    const currentLang = this.$lang.slice(0, 2) === 'fr' ? 'fr' : 'en';
    await this.startOkta(currentLang);
    const showSignInToGetTokensCallback = await this.showSignInToGetTokens(this.oktaSignIn);
    this.$nextTick(await showSignInToGetTokensCallback);
  },
  unmounted() {
    this.oktaSignIn.remove();
  },
  methods: {
    async processCreds(creds, callback) {
      await handleCreds(creds, callback, this.setErrorMessage, this.postAuthAndGetRedirectUrl);
    },
    async startOkta(currentLang) {
      oktaI18n.en['primaryauth.title'] = this.title.en;
      oktaI18n.fr['primaryauth.title'] = this.title.fr;
      this.oktaSignIn = getOktaSignIn(this.processCreds, currentLang, oktaI18n);
    },
    async showSignInToGetTokens(oktaInstance) {
      return async () => showSignInToGetTokens(oktaInstance, { el: '#okta-signin-container' }, this.postAuthAndGetRedirectUrl);
    },
    setErrorMessage(value) {
      this.errorMessage = value;
    },
  },
};
</script>

<style lang="scss">
@import '~@okta/okta-signin-widget/dist/css/okta-sign-in.min.css';
#okta-sign-in {
  width: auto;
  min-width: auto;
  font-family: $font-stack;
  font-feature-settings: $font-features;
  font-size: 1rem;
  overflow: inherit;
  margin: 0px auto 8px;

  &.auth-container {
    &.main-container {
      box-shadow: none;
      font-family: $font-stack;
      border: none;
      color: $black;

      .primary-auth-form {
        .margin-btm-30 {
          margin-bottom: 0;
        }
      }

      .auth-footer {
        text-align: center;
        .goto {
          float: none;
        }
      }
    }

    .o-form {
      .o-form-label {
        font-size: 1rem;
        font-weight:normal;
        padding: 0px 10px 7px 0;
      }
    }

    .okta-form-subtitle {
      color: $black;
    }

    h2 {
      text-align: left;
      font-size: 1.875rem;
      line-height: 2.25rem;
      margin: 1.87rem 0 1rem;
      font-weight: bold;
      padding: 0;
      color: $black;
    }

    h3 {
      font-size: 1.25rem;
      font-weight: bold;
      color: $black;
    }

    input[type=submit],
    .button-primary,
    .button-wide,
    .link-button {
      font-family: $font-stack;
      font-weight: bold;
      font-size: 1rem;
      display: inline-block;
      height: auto;
      line-height: 1.125rem;
      text-align: center;
      justify-content: center;
      text-decoration: none;
      padding: 0.5rem 0.75rem;
      margin: 0;
      border: 1px solid transparent;
      border-radius: 0;
      white-space: nowrap;
      transition: all 0.3s ease-out;
      stroke: transparent;
      @include fill($primary-500, darken($primary-500, 10%), darken($primary-500, 10%), $text: $white );

      &.sms-request-button {
        margin-top: 1.31rem;
      }

      &.link-button-disabled,
      &.link-button-disabled:disabled,
      &.link-button-disabled:focus,
      &.btn-disabled,
      &.btn-disabled:disabled,
      &.btn-disabled:focus {
        border-color: $neutral-300;
        color: $neutral-500;
        font-weight: normal;
        fill: $neutral-500;
        background: $neutral-50;
        outline: none;
        cursor: default;
        box-shadow: none;
      }
    }

    .link-button.link {
      @include fill($primary-500, darken($primary-500, 10%), darken($primary-500, 10%), $text: $white );
      &.link-button-disabled,
      &.link-button-disabled:disabled,
      &.link-button-disabled:focus,
      &.btn-disabled,
      &.btn-disabled:disabled,
      &.btn-disabled:focus {
        border-color: $neutral-300;
        color: $neutral-500;
        font-weight: normal;
        fill: $neutral-500;
        background: $neutral-50;
        outline: none;
        cursor: default;
        box-shadow: none;
      }
    }

    .o-form-explain {
      &.okta-form-input-error {
        font-size: 0.875rem;
        padding-top: 0.135rem;
        margin-bottom: 1rem;
      }
    }

    .forgot-password {
      .forgot-password-email-enabled {
        .o-form-explain:not(.okta-form-input-error) {
          display: none;
        }
      }
    }

    .o-form-head {
      &+.o-form-explain {
        text-align: left;
      }
    }
    .link.help.js-help {
      display: none;
    }
  }

  .mfa-verify-totp-inline {
    a.link-button {
      &.margin-top-30 {
        margin-top: 1.31rem;
      }
    }
  }

  .custom-checkbox,
  .custom-radio {
    padding-bottom: 0.75rem;

    label {
      background-image: none;
      background-size: 0 0;
      font-weight: normal;
      font-size: 1rem;
      line-height: 1.25rem;
      padding: 0px 12px 0 2rem;

      &.checked {
        background-position: 0 0;
      }

      &.focus:before {
        background-position: 1px 1px
      }
    }
  }

  fieldset *:last-child {
    margin-bottom: auto;
  }

  .okta-sign-in-header {
    display: none;
  }

  .okta-form-title {
    text-align: left;
    font-size: 1.875rem;
    line-height: 2.25rem;
    margin: 2.25rem 0 1rem;
    font-weight: bold;
    padding: 0;
    color: $black;
  }

  .auth-content {
    padding: 0 0 0;
    transition: padding-top .4s;
  }

  &.no-beacon {
    .auth-content {
      padding-top: 0;
    }
  }

  .enroll-factor-row {
    .enroll-factor-button {
      .button {
        font-family: $font-stack;
        font-size: 1rem;
        display: inline-block;
        height: auto;
        font-weight: bold;
        line-height: 1.125rem;
        text-align: center;
        justify-content: center;
        text-decoration: none;
        padding: 0.5rem 0.75rem;
        margin: 4px 4px;
        border: 1px solid transparent;
        border-radius: 0;
        white-space: nowrap;
        transition: all 0.3s ease-out;
        stroke: transparent;
        @include fill($primary-500, darken($primary-500, 10%), darken($primary-500, 10%), $text: $white );
      }
    }
  }

  .o-form {
    label {
      font-size: 1rem;
      color: $black;
      font-weight: bold;
    }

    .custom-checkbox,
    .custom-radio {
      padding-bottom: 0.75rem;

      label {
        background-image: none;
        background-size: 0 0;
        font-weight: normal;
        font-size: 1rem;
        line-height: 1.25rem;
        padding: 0px 12px 0 2rem;

        &.checked {
          background-position: 0 0;
        }

        &.focus:before {
          background-position: 1px 1px
        }
      }
    }

    .input-fix {
      border: none;
      height: auto;

      input[type=text],
      input[type=password],
      input[name=passCode],
      input[type=tel],
      input[type=email] {
        font-family: $font-stack;
        font-feature-settings: $font-features;
        font-weight: normal;
        font-size: 1rem;
        line-height: 1.375rem;
        display: block;
        overflow: auto;
        -webkit-appearance: none;
        width: 100%;
        padding: 0.25rem 0.5rem;
        margin: 0 0;
        height: 2rem;
        transition: all 0.3s ease-out;
        border: 1px solid $neutral-300;
        border-radius: 0;
        background-color: $white;
        color: $black;
      }
    }
  }

  .o-form-input-name-username,
  .o-form-input-name-password {
    margin-bottom: 1.25rem;
  }

  .o-form-has-errors {
    .input-fix {
      &.o-form-input-name-username,
      &.o-form-input-name-password {
        margin-bottom: 0;
      }
    }
  }

  .o-form-button-bar {
    .button {
      height: auto;

      &:focus-visible {
          outline: none;
      }
    }
  }

  .primary-auth-form {
    .o-form-button-bar {
      padding: 0 0 0.5rem;
      &.focused-input {
        margin-bottom: 0;
      }
    }
  }

  .focused-input,
  .link.help:focus {
    box-shadow: none;
  }

  .o-form-input-error {
    padding-top: 0px;

    .icon-16 {
      left: -1px;
      top: 0.035rem;
    }
  }

  .app-download-instructions {
    display: flex;

    .app-logo {
      width: auto;
      min-width: 38px;
    }
  }

  .o-form-label-inline {
    padding: 2px 10px;
  }
}

.mfa-verify {
  .o-form-fieldset {
      &.o-form-label-top {
        float: left;
      }
  }
}

.logged-out {
  #okta-sign-in {
    &.auth-container {
      h2 {
        &.okta-form-title {
          font-size: 1.5rem;
          line-height: 1.875rem;
        }
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.forgot-password {
  text-align: center;
  margin-top: 0.5rem;
}

.post-error {
  margin-top: 1rem;
  color: $neg-500;
  text-align: center;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 500ms;
}

.fade-enter, .fade-leave-to {
  opacity: 0
}
</style>
