import axios from 'axios';
// import { getLanguage } from '../../utils/locale'

/* we shouldn't be testing axios in unit tests */

/* istanbul: ignore next */
export const http = axios.create({
  baseURL: process.env.API_URL,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
    // 'locale': getLanguage() === 'french' ? 'fr' : 'en'
  },
});

/* we should test our error handler thought */
export function handleError(window) {
  return function execute(error) {
    if (
      error.response.status === 401
      && !!window.location.pathname.indexOf('/signup')
      && !!window.location.pathname.indexOf('/two-factor')
      && !!window.location.pathname.indexOf('/logged-out')
      && !!window.location.pathname.indexOf('/signin')
      && !!window.location.pathname.indexOf('/representative-invitation')
    ) {
      // We don't reload for signup and 2 factor pages since the user is going to sign in
      // TODO replace window.location with a endpoint-based rules
      window.location.reload();
    }
    return Promise.reject(error);
  };
}

/* istanbul: ignore next */
http.interceptors.response.use(
  undefined,
  handleError(window),
);
