<template>
  <header role="banner" :data-collapsed="isMenuCollapsed ? 'true' : 'false'" class="notranslate">
    <nav id="nav-banner" :class="theme">
      <div class="container-banner">
        <a :href="[loggedIn ? '/app' : '/']" target="_self">
          <svg class="wealthbar-logo english-only" viewBox="0 0 140 48">
            <title>CI Direct Investing</title>
            <g id="logo-colour-en" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <g fill="#1B252C">
                <path d="M2.468,45.506 L45.505,45.506 L45.505,2.468 L2.468,2.468 L2.468,45.506 Z M8.17124146e-14,47.973 L47.974,47.973 L47.974,-2.13162821e-14 L8.17124146e-14,-2.13162821e-14 L8.17124146e-14,47.973 Z" ></path>
                <polygon  points="33.871 36.495 38.398 36.495 38.398 11.65 33.871 11.65"></polygon>
                <path d="M25.586,30.586 L25.586,30.586 C24.304,31.479 22.867,31.935 21.185,31.935 C16.796,31.935 13.238,28.376 13.238,23.987 C13.238,19.599 16.796,16.04 21.185,16.04 C23.011,16.04 24.685,16.666 26.027,17.703 L29.978,14.404 C27.632,12.212 24.491,10.859 21.026,10.859 C13.775,10.859 7.897,16.737 7.897,23.987 C7.897,31.237 13.775,37.115 21.026,37.115 C24.976,37.115 28.507,35.361 30.915,32.6 C30.803,32.447 28.199,28.547 25.586,30.586" ></path>
              </g>
              <path d="M62.4909272,14.4693069 L60.9551336,14.4693069 L60.9551336,24.5782178 L62.4909272,24.5782178 C65.4928413,24.5782178 67.9751933,22.9326733 67.9751933,19.5227723 C67.9751933,16.1148515 65.4928413,14.4693069 62.4909272,14.4693069 Z M62.8691524,27 L58,27 L58,12 L62.8691524,12 C67.1470791,12 71,14.7514851 71,19.5 C71,24.2485149 67.1470791,27 62.8691524,27 L62.8691524,27 Z" fill="#1E272E"></path>
              <polygon fill="#1E272E" points="75 27 78 27 78 12 75 12"></polygon>
              <path d="M88.470856,14.4693069 L86.8505,14.4693069 L86.8505,18.4188119 L88.470856,18.4188119 C89.5839363,18.4188119 90.6726282,17.8069307 90.6726282,16.4445545 C90.6726282,15.1039604 89.6063735,14.4693069 88.470856,14.4693069 Z M92.4773596,27 L88.1694171,20.7465347 L86.8505,20.7465347 L86.8505,27 L84,27 L84,12 L88.934233,12 C91.2511178,12 93.5436143,13.5287129 93.5436143,16.3742574 C93.5436143,18.2534653 92.4549224,19.5950495 91.1116169,20.1811881 L96,27 L92.4773596,27 L92.4773596,27 Z" fill="#1E272E"></path>
              <polyline fill="#1E272E" points="99 27 99 12 107.257571 12 107.257571 14.4693069 101.899381 14.4693069 101.899381 18.2306931 106.352979 18.2306931 106.352979 20.7227723 101.899381 20.7227723 101.899381 24.5316832 108 24.5316832 108 27 99 27"></polyline>
              <path d="M123.472379,14.6517477 C122.760187,14.1863795 121.474956,13.7189475 120.122081,13.7189475 C117.414398,13.7189475 114.913513,15.6309816 114.913513,19.0608797 C114.913513,22.4680769 117.392173,24.4028118 120.213883,24.4028118 C121.681752,24.4028118 122.783379,24.1087321 123.608633,23.6185993 L123.357384,26.3385786 C122.508939,26.7791823 121.474956,27 119.80029,27 C116.082783,27 112,24.2800206 112,19.0123823 C112,13.7189475 116.130134,11 119.80029,11 C121.704944,11 123.10517,11.4406036 124,11.9802657 L123.472379,14.6517477" fill="#1E272E"></path>
              <polyline fill="#1E272E" points="134.470381 14.4693069 134.470381 27 131.528636 27 131.528636 14.4693069 127 14.4693069 127 12 139 12 139 14.4693069 134.470381 14.4693069"></polyline>
              <polygon fill="#1E272E" points="58 38 59 38 59 31 58 31"></polygon>
              <path d="M68.6957364,38 L65.5581395,34.4885961 C65.0552326,33.9312934 64.4786822,33.2430939 64.0174419,32.6847995 L63.996124,32.6966992 C64.0174419,33.3194503 64.0174419,33.8985692 64.0174419,34.414223 L64.0174419,37.8492704 L63,37.8492704 L63,31 L63.7025194,31 L66.5765504,34.2208528 C66.9641473,34.6601502 67.5726744,35.347358 67.9815891,35.8630118 L68.003876,35.8530953 C67.9815891,35.3265335 67.9815891,34.7365066 67.9815891,34.2416773 L67.9815891,31 L69,31 L69,38 L68.6957364,38" fill="#1E272E"></path>
              <path d="M75.1792582,38 L74.8320742,38 L72,31 L73.1363324,31 L74.4539835,34.288651 C74.6425137,34.7653105 74.854739,35.3478944 75.0442995,35.9324768 L75.0659341,35.9324768 C75.2565247,35.3598858 75.4563874,34.7862955 75.65625,34.288651 L76.9739011,31 L78,31 L75.1792582,38" fill="#1E272E"></path>
              <polyline fill="#1E272E" points="82 38 82 31 85.6306043 31 85.6306043 31.9100912 83.0233918 31.9100912 83.0233918 34.0282322 85.2076023 34.0282322 85.2076023 34.9393369 83.0233918 34.9393369 83.0233918 37.0888953 86 37.0888953 86 38 82 38"></polyline>
              <path d="M91.7745144,38 C91.0762672,38 90.4500237,37.7838338 90.1127428,37.5457521 L90,36.4579479 C90.4708669,36.8354917 91.1378494,37.0934965 91.825675,37.0934965 C92.5333965,37.0934965 93.0052108,36.6830796 93.0052108,36.0684503 C93.0052108,35.4428632 92.6660351,35.0981927 91.8967314,34.8391917 C90.747513,34.4516864 90.1421127,33.8370571 90.1421127,32.8438879 C90.1421127,31.8945496 90.8090952,31 92.1837991,31 C92.8204642,31 93.3538607,31.2042123 93.5888205,31.3885015 L93.3945997,32.2621318 C93.1586926,32.0897965 92.6660351,31.8626725 92.1430602,31.8626725 C91.4969209,31.8626725 91.0971104,32.2830511 91.0971104,32.769176 C91.0971104,33.2652626 91.4457603,33.5661022 92.122217,33.8041839 C93.2401705,34.2026469 94,34.7535221 94,35.971823 C94,37.1801622 93.2306964,38 91.7745144,38" fill="#1E272E"></path>
              <polyline  fill="#1E272E" points="99.9786846 31.9100912 99.9786846 38 99.0213154 38 99.0213154 31.9100912 97 31.9100912 97 31 102 31 102 31.9100912 99.9786846 31.9100912"></polyline>
              <polygon fill="#1E272E" points="106 38 107 38 107 31 106 31"></polygon>
              <path d="M116.695786,38 L113.559664,34.4885961 C113.05587,33.9312934 112.479412,33.2430939 112.017278,32.6847995 L111.996932,32.6966992 C112.017278,33.3194503 112.017278,33.8985692 112.017278,34.414223 L112.017278,37.8492704 L111,37.8492704 L111,31 L111.703375,31 L114.577911,34.2208528 C114.965445,34.6601502 115.572905,35.347358 115.981754,35.8630118 L116.003068,35.8530953 C115.981754,35.3265335 115.981754,34.7365066 115.981754,34.2416773 L115.981754,31 L117,31 L117,38 L116.695786,38" fill="#1E272E"></path>
              <path d="M124.602662,38 C122.831749,38 121,36.7860426 121,34.5053629 C121,32.1710545 122.903992,31 124.540875,31 C125.445817,31 126.176806,31.2223151 126.661597,31.5392116 L126.474335,32.4460231 C125.991445,32.1291266 125.344106,31.9087617 124.653992,31.9087617 C123.367871,31.9087617 122.008555,32.7736454 122.008555,34.5355899 C122.008555,36.2790082 123.326046,37.1331662 124.663498,37.1331662 C125.270913,37.1331662 125.745247,37.0073826 126.054183,36.7860426 L126.054183,35.2971166 L124.540875,35.2971166 L124.540875,34.4741607 L127,34.4741607 L127,37.2706505 C126.372624,37.7884106 125.579848,38 124.602662,38" fill="#1E272E"></path>
            </g>
          </svg>
          <svg class="wealthbar-logo french-only" viewBox="0 0 155 48">
            <title>Investissement direct CI</title>
            <g id="logo-colour-fr" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <g fill="#1B252C">
                <path d="M2.468,45.506 L45.505,45.506 L45.505,2.468 L2.468,2.468 L2.468,45.506 Z M8.17124146e-14,47.973 L47.974,47.973 L47.974,-2.13162821e-14 L8.17124146e-14,-2.13162821e-14 L8.17124146e-14,47.973 Z"></path>
                <polygon points="33.871 36.495 38.398 36.495 38.398 11.65 33.871 11.65"></polygon>
                <path d="M25.586,30.586 L25.586,30.586 C24.304,31.479 22.867,31.935 21.185,31.935 C16.796,31.935 13.238,28.376 13.238,23.987 C13.238,19.599 16.796,16.04 21.185,16.04 C23.011,16.04 24.685,16.666 26.027,17.703 L29.978,14.404 C27.632,12.212 24.491,10.859 21.026,10.859 C13.775,10.859 7.897,16.737 7.897,23.987 C7.897,31.237 13.775,37.115 21.026,37.115 C24.976,37.115 28.507,35.361 30.915,32.6 C30.803,32.447 28.199,28.547 25.586,30.586"></path>
              </g>
              <path d="M62.511,23.69 L60.935,23.69 L60.935,34.113 L62.511,34.113 C65.59,34.113 68.135,32.416 68.135,28.901 C68.135,25.386 65.59,23.69 62.511,23.69 Z M62.899,36.609 L57.905,36.609 L57.905,21.144 L62.899,21.144 C67.286,21.144 71.237,23.98 71.237,28.877 C71.237,33.773 67.286,36.609 62.899,36.609 L62.899,36.609 Z" fill="#1E272E"></path>
              <polygon fill="#1E272E" points="75.693 36.609 78.748 36.609 78.748 21.144 75.693 21.144"></polygon>
              <path d="M88.926,23.69 L87.229,23.69 L87.229,27.762 L88.926,27.762 C90.09,27.762 91.228,27.131 91.228,25.726 C91.228,24.344 90.114,23.69 88.926,23.69 Z M93.12,36.609 L88.611,30.161 L87.229,30.161 L87.229,36.609 L84.248,36.609 L84.248,21.144 L89.411,21.144 C91.835,21.144 94.234,22.72 94.234,25.652 C94.234,27.592 93.095,28.974 91.689,29.58 L96.804,36.609 L93.12,36.609 L93.12,36.609 Z" fill="#1E272E"></path>
              <polyline fill="#1E272E" points="99.854 36.609 99.854 21.144 108.484 21.144 108.484 23.69 102.885 23.69 102.885 27.568 107.539 27.568 107.539 30.137 102.885 30.137 102.885 34.064 109.26 34.064 109.26 36.609 99.854 36.609"></polyline>
              <path d="M124.818,24.562 C124.067,24.102 122.71,23.642 121.279,23.642 C118.419,23.642 115.777,25.531 115.777,28.925 C115.777,32.295 118.395,34.21 121.377,34.21 C122.928,34.21 124.091,33.919 124.964,33.434 L124.697,36.125 C123.801,36.56 122.71,36.779 120.94,36.779 C117.012,36.779 112.698,34.089 112.698,28.877 C112.698,23.642 117.061,20.95 120.94,20.95 C122.952,20.95 124.43,21.387 125.376,21.92 L124.818,24.562" fill="#1E272E"></path>
              <polyline fill="#1E272E" points="136.571 23.69 136.571 36.609 133.516 36.609 133.516 23.69 128.814 23.69 128.814 21.144 141.273 21.144 141.273 23.69 136.571 23.69"></polyline>
              <polygon fill="#1E272E" points="58.263 16.764 59.209 16.764 59.209 10.538 58.263 10.538"></polygon>
              <path d="M66.849,16.9 L63.931,13.71 C63.463,13.202 62.926,12.578 62.497,12.07 L62.477,12.08 C62.497,12.646 62.497,13.173 62.497,13.642 L62.497,16.764 L61.551,16.764 L61.551,10.538 L62.204,10.538 L64.878,13.466 C65.239,13.866 65.804,14.49 66.185,14.959 L66.205,14.949 C66.185,14.471 66.185,13.934 66.185,13.485 L66.185,10.538 L67.132,10.538 L67.132,16.9 L66.849,16.9" fill="#1E272E"></path>
              <path d="M71.522,16.852 L71.219,16.852 L68.741,10.538 L69.736,10.538 L70.887,13.505 C71.054,13.934 71.239,14.461 71.405,14.988 L71.425,14.988 C71.591,14.471 71.766,13.953 71.942,13.505 L73.094,10.538 L73.991,10.538 L71.522,16.852" fill="#1E272E"></path>
              <polyline fill="#1E272E" points="75.6 16.764 75.6 10.538 78.957 10.538 78.957 11.348 76.547 11.348 76.547 13.232 78.566 13.232 78.566 14.041 76.547 14.041 76.547 15.954 79.299 15.954 79.299 16.764 75.6 16.764"></polyline>
              <path d="M82.46,16.813 C81.796,16.813 81.201,16.618 80.879,16.403 L80.771,15.417 C81.22,15.759 81.854,15.993 82.509,15.993 C83.181,15.993 83.631,15.623 83.631,15.066 C83.631,14.5 83.308,14.188 82.577,13.953 C81.483,13.602 80.908,13.046 80.908,12.148 C80.908,11.29 81.542,10.48 82.85,10.48 C83.455,10.48 83.962,10.665 84.186,10.831 L84.001,11.622 C83.777,11.466 83.308,11.26 82.81,11.26 C82.196,11.26 81.815,11.641 81.815,12.08 C81.815,12.529 82.147,12.802 82.791,13.017 C83.854,13.378 84.577,13.876 84.577,14.978 C84.577,16.071 83.845,16.813 82.46,16.813" fill="#1E272E"></path>
              <polyline fill="#1E272E" points="88.548 11.348 88.548 16.764 87.601 16.764 87.601 11.348 85.601 11.348 85.601 10.538 90.549 10.538 90.549 11.348 88.548 11.348"></polyline>
              <polygon fill="#1E272E" points="92.303 16.764 93.249 16.764 93.249 10.538 92.303 10.538"></polygon>
              <path d="M97.094,16.813 C96.43,16.813 95.836,16.618 95.514,16.403 L95.406,15.417 C95.855,15.759 96.489,15.993 97.143,15.993 C97.816,15.993 98.265,15.623 98.265,15.066 C98.265,14.5 97.943,14.188 97.212,13.953 C96.118,13.602 95.543,13.046 95.543,12.148 C95.543,11.29 96.177,10.48 97.485,10.48 C98.09,10.48 98.598,10.665 98.821,10.831 L98.635,11.622 C98.412,11.466 97.943,11.26 97.445,11.26 C96.831,11.26 96.45,11.641 96.45,12.08 C96.45,12.529 96.782,12.802 97.426,13.017 C98.49,13.378 99.212,13.876 99.212,14.978 C99.212,16.071 98.48,16.813 97.094,16.813" fill="#1E272E"></path>
              <path d="M102.324,16.813 C101.66,16.813 101.065,16.618 100.743,16.403 L100.636,15.417 C101.085,15.759 101.719,15.993 102.373,15.993 C103.047,15.993 103.495,15.623 103.495,15.066 C103.495,14.5 103.174,14.188 102.441,13.953 C101.348,13.602 100.772,13.046 100.772,12.148 C100.772,11.29 101.407,10.48 102.715,10.48 C103.319,10.48 103.827,10.665 104.052,10.831 L103.866,11.622 C103.641,11.466 103.174,11.26 102.676,11.26 C102.06,11.26 101.679,11.641 101.679,12.08 C101.679,12.529 102.012,12.802 102.656,13.017 C103.72,13.378 104.442,13.876 104.442,14.978 C104.442,16.071 103.71,16.813 102.324,16.813" fill="#1E272E"></path>
              <polyline fill="#1E272E" points="106.247 16.764 106.247 10.538 109.603 10.538 109.603 11.348 107.193 11.348 107.193 13.232 109.213 13.232 109.213 14.041 107.193 14.041 107.193 15.954 109.945 15.954 109.945 16.764 106.247 16.764"></polyline>
              <path d="M117.829,16.764 L117.575,13.953 C117.536,13.534 117.497,12.998 117.477,12.539 L117.458,12.539 C117.262,12.978 116.96,13.583 116.755,13.983 L115.31,16.813 L114.96,16.813 L113.496,13.983 C113.281,13.573 113.017,13.017 112.803,12.558 L112.783,12.558 C112.763,13.037 112.715,13.583 112.685,13.953 L112.442,16.764 L111.602,16.764 L112.139,10.538 L112.724,10.538 L114.384,13.739 C114.628,14.217 114.96,14.861 115.165,15.339 L115.184,15.339 C115.38,14.861 115.681,14.256 115.945,13.739 L117.585,10.538 L118.17,10.538 L118.726,16.764 L117.829,16.764" fill="#1E272E"></path>
              <polyline fill="#1E272E" points="120.579 16.764 120.579 10.538 123.935 10.538 123.935 11.348 121.525 11.348 121.525 13.232 123.546 13.232 123.546 14.041 121.525 14.041 121.525 15.954 124.277 15.954 124.277 16.764 120.579 16.764"></polyline>
              <path d="M131.234,16.9 L128.316,13.71 C127.848,13.202 127.311,12.578 126.882,12.07 L126.862,12.08 C126.882,12.646 126.882,13.173 126.882,13.642 L126.882,16.764 L125.935,16.764 L125.935,10.538 L126.59,10.538 L129.263,13.466 C129.624,13.866 130.19,14.49 130.571,14.959 L130.591,14.949 C130.571,14.471 130.571,13.934 130.571,13.485 L130.571,10.538 L131.517,10.538 L131.517,16.9 L131.234,16.9" fill="#1E272E"></path>
              <polyline fill="#1E272E" points="135.927 11.348 135.927 16.764 134.98 16.764 134.98 11.348 132.98 11.348 132.98 10.538 137.928 10.538 137.928 11.348 135.927 11.348"></polyline>
            </g>
          </svg>
        </a>
        <a v-if="!navPublic && link" class="banner-link" target="_self" :href="link.url">{{link.title}}</a>
        <ul v-if="navPublic" class="small-screen-hide">
          <li v-if="link">
            <a class="banner-link" target="_self" :href="link.url">{{link.title}}</a>
          </li>
          <li>
            <a href="/pricing" class="banner-link" :class="{ active: atLocation('/pricing') }" target="_self" v-t>Pricing</a>
          </li>
          <li>
            <a href="/investments" class="banner-link" :class="{ active: atLocation('/investments') }" target="_self" v-t>Investments</a>
          </li>
          <li>
            <a href="https://blog.cidirectinvesting.com/" class="banner-link" target="_self" v-t>Blog</a>
          </li>
          <li>
            <a href="/signin" class="banner-link" target="_self" v-t>Sign&nbsp;in</a>
          </li>
          <li>
            <a class="button" :href="signupUrl" target="_self" v-t>Start Investing</a>
          </li>
        </ul>
        <ul v-if="navPublic" class="large-screen-hide">
          <li>
            <a href="/signin" class="banner-link" target="_self" v-t>Sign&nbsp;in</a>
          </li>
          <li>
            <button type="button" id="nav-dropdown-toggle" class="flat icon" @click="toggleMenu">
              <svg  version="1.1" viewBox="0 0 48 48" height="12" width="12" xmlns="http://www.w3.org/2000/svg">
                <title>Menu</title>
                <g id="hamburger" fill="#303030">
                  <rect x="0" y="2" width="48" height="4"></rect>
                  <rect x="0" y="22" width="48" height="4"></rect>
                  <rect x="0" y="42" width="48" height="4"></rect>
                </g>
                <polygon id="close" points="45 0 24 21 3 0 0 3 21 24 0 45 3 48 24 27 45 48 48 45 27 24 48 3"></polygon>
              </svg>
            </button>
          </li>
        </ul>
      </div>
    </nav>
    <nav v-if="navPublic" id="nav-dropdown" class="large-screen-hide">
      <ul>
        <li v-if="link" >
          <a class="menu-link" :href="link.url" target="_self" >{{link.title}}</a>
        </li>
        <li>
          <a class="menu-link" href="/pricing" :class="{ active: atLocation('/pricing') }" target="_self" v-t>Pricing</a>
        </li>
        <li>
          <a class="menu-link" href="/investments" :class="{ active: atLocation('/investments') }" target="_self" v-t>Investments</a>
        </li>
        <li>
          <a class="menu-link" href="https://blog.cidirectinvesting.com/" target="_self" v-t>Blog</a>
        </li>
        <li>
          <a class="button" :href="signupUrl" target="_self" v-t>Start Investing</a>
        </li>
      </ul>
    </nav>
  </header>
</template>

<script>
import { getSignupUrl } from '~utils/external_links';

export default {
  props: {
    theme: {
      type: String,
      default: '',
    },
    loggedIn: {
      type: Boolean,
      default: false,
    },
    link: {
      type: Object,
      validator: obj => obj.title && obj.url,
      default: null,
    },
    navPublic: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isMenuCollapsed: true,
      signupUrl: '',
    };
  },
  watch: {
    $lang: {
      handler(newVal) {
        this.signupUrl = getSignupUrl(newVal);
      },
      immediate: true,
    },
  },
  methods: {
    toggleMenu() {
      this.isMenuCollapsed = !this.isMenuCollapsed;
    },
    atLocation(location) {
      return !!window.location.pathname.match(location);
    },
  },
};
</script>
