export function getOktaDomain(process = window.process) {
  if (process && process.env && process.env.OKTA_DOMAIN) {
    return process.env.OKTA_DOMAIN;
  }
  return '';
}

export function getOktaClientID(process = window.process) {
  if (process && process.env && process.env.OKTA_CLIENT_ID) {
    return process.env.OKTA_CLIENT_ID;
  }
  return '';
}

export function getOktaRedirectURI(process = window.process, location = window.location) {
  if (process && process.env && process.env.OKTA_REDIRECT_URI) {
    return process.env.OKTA_REDIRECT_URI;
  }
  return `${location.protocol}//${location.host}/signin/callback`;
}

export const OktaSettings = {
  domain: getOktaDomain(window.process),
  clientId: getOktaClientID(window.process, window.location),
  redirectUri: getOktaRedirectURI(window.process),
  scopes: ['openid', 'okta.users.read.self', 'okta.users.manage.self'],
};
