<template>
  <div class="notranslate">
    <vue-form :state="formstate" @submit.prevent="signup">
      <div v-if="nameEntry" class="field-name">
        <validate>
          <label v-t for="firstname" class="p-small">First Name</label>
          <input id="firstname" v-model="model.first_name" name="firstname" type="text" required />
          <field-messages name="firstname" show="$touched || $submitted" class="error-message">
            <span slot="required">{{ $t('Required') }}</span>
          </field-messages>
        </validate>

        <validate>
          <label v-t for="lastname" class="p-small">Last Name</label>
          <input id="lastname" v-model="model.last_name" name="lastname" type="text" required />
          <field-messages name="lastname" show="$touched || $submitted" class="error-message">
            <span slot="required">{{ $t('Required') }}</span>
          </field-messages>
        </validate>
      </div>

      <validate :custom="{ isValidEmail }">
        <label v-t for="email">Email</label>
        <input id="email" v-model="model.email" v-focus="true" name="email" required @input="clearError" />
        <field-messages name="email" show="$touched || $submitted" class="error-message">
          <span slot="isValidEmail">{{ $t('Invalid email') }}</span>
          <span slot="required">{{ $t('Required') }}</span>
        </field-messages>
      </validate>

      <validate :custom="{ isPasswordValid }">
        <label v-t for="password">Password</label>
        <input-password
          v-model="model.password"
          name="password"
          class="input"
          :minlength="8"
          :required="true"
          @input="clearOnlyGenericError"
        />
        <field-messages name="password" show="$touched || $submitted" class="error-message">
          <span slot="required">{{ $t('Required') }}</span>
          <span slot="isPasswordValid">{{ $t('Please ensure the following requirements are met:') }}</span>
        </field-messages>
      </validate>
      <div class="password-validation" v-if="model.password">
        <ul>
          <li v-t :class="{ 'valid': passwordErrors.minLength }">At least 8 characters</li>
          <li v-t :class="{ 'valid': passwordErrors.number }">At least 1 number</li>
          <li v-t :class="{ 'valid': passwordErrors.symbol }">At least 1 symbol</li>
          <li v-t :class="{ 'valid': passwordErrors.lowerCase }">At least 1 lowercase letter</li>
          <li v-t :class="{ 'valid': passwordErrors.upperCase }">At least 1 uppercase letter</li>
          <li v-t :class="{ 'valid': passwordErrors.email }">Does not contain part of the email</li>
          <li v-if="nameEntry" v-t :class="{ 'valid': passwordErrors.firstName }">Does not contain 'First Name'</li>
          <li v-if="nameEntry" v-t :class="{ 'valid': passwordErrors.lastName }">Does not contain 'Last Name'</li>
        </ul>
      </div>

      <mcelvaine-agreement v-if="partnerChannel === '3p-financial'" @setAgreed="handleAgreed"></mcelvaine-agreement>
      
      <horizons-agreement v-if="partnerChannel === 'horizons'" @setAgreed="handleAgreed"></horizons-agreement>

      <button type="submit" class="button" :disabled="disableSignup">{{ signupButtonCopy }}</button>

      <p class="privacy-policy">
        <small>
          <span v-t>By signing up, you agree to our</span>
          <a v-t :href="model.language === 'english' ? 'https://www.cifinancial.com/ci-di/ca/en/clientdisclosure.html' : 'https://www.cifinancial.com/ci-di/ca/fr/clientdisclosure.html'" target="_blank">Privacy Policy</a>
          <span v-if="brand === 'assante'">&nbsp;<span v-t>and</span>
            <a v-t href="/terms-of-service" target="_blank">Terms of Service</a>
          </span>.
        </small>
      </p>

      <transition name="fade">
        <p v-if="error && userExists" class="error post-error">
          <span v-t>Email has already been taken</span><span>.</span>
        </p>
        <p v-else-if="error && agreementRequired" class="error post-error">
          <span>You must agree to the referral agreement before proceeding.</span>
        </p>
        <p v-else-if="error" class="error post-error">
          <span v-t>Something went wrong. Please try again later, or </span>
          <a v-t v-intercom>contact us</a>
          <span v-t> if the issue persists.</span>
        </p>
      </transition>
    </vue-form>
  </div>
</template>

<script>
import VueForm from 'vue-form';
import { getLanguage } from 'utils/locale';
import { http } from '~vue/helpers/http';
import { checkEmailValidity } from '~vue/helpers/check-email-validity';
import { isValidPassword, getPasswordValidity } from '~vue/helpers/check-password-validity';
import { BRAND } from '~vue/config/brand';
import InputFocus from '~vue/directives/input-focus';
import Intercom from '~vue/directives/intercom';
import InputPassword from './input-password';
import McelvaineAgreement from './agreements/mcelvaine-agreement';
import HorizonsAgreement from './agreements/horizons-agreement'

export default {
  directives: {
    focus: InputFocus,
    intercom: Intercom,
  },
  components: {
    InputPassword,
    McelvaineAgreement,
    HorizonsAgreement,
  },
  mixins: [VueForm],
  props: {
    user: {
      type: Object,
      default: () => ({
        firstName: null,
        lastName: null,
        email: null,
      }),
    },
    nameEntry: {
      type: Boolean,
      default: false,
    },
    signupButton: {
      type: String,
      default: '',
    },
    partnerChannel: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      formstate: {},
      model: {
        first_name: this.user.firstName,
        last_name: this.user.lastName,
        email: this.user.email,
        password: '',
        subscribed_to_newsletter: false,
        language: '',
        agreed_to_agreement: false,
      },
      error: null,
      passwordErrors: {
        minLength: false,
        number: false,
        symbol: false,
        lowerCase: false,
        upperCase: false,
        email: false,
        firstName: false,
        lastName: false,
      },
      brand: BRAND,
    };
  },
  computed: {
    signupButtonCopy() {
      return this.signupButton || this.$t('Sign up');
    },
    subscribeMessage() {
      if (this.brand === 'assante') {
        return this.$t('Receive our newsletter and special offers');
      }
      return this.$t('Help me save money with tips and news');
    },
    userExists() {
      return this.error && this.error.errors && this.error.errors.email === 'has already been taken';
    },
    agreementRequired() {
      return this.error && this.error.message && this.error.message === 'You must agree to the referral agreement before proceeding.';
    },
    isPasswordValid() {
      return isValidPassword(this.model.password || '', this.model.email || '', this.model.first_name || '', this.model.last_name || '');
    },
    disableSignup() {
      return (this.partnerChannel === '3p-financial' || this.partnerChannel === 'horizons') && this.model.agreed_to_agreement !== true;
    },
  },
  watch: {
    user(value) {
      this.model.first_name = value.firstName;
      this.model.last_name = value.lastName;
      this.model.email = value.email;
    },
    signupButton(value) {
      this.signupButton = value;
    },
    model: {
      handler() {
        this.passwordErrors = getPasswordValidity(this.model.password || '', this.model.email || '', this.model.first_name || '', this.model.last_name || '');
      },
      deep: true,
    },
  },
  mounted() {
    this.model.language = this.getLanguage();
  },
  methods: {
    async signup() {
      this.clearError();
      if (this.formstate.$invalid) return;

      this.model.language = this.getLanguage();
      this.model.partner_channel = this.partnerChannel;

      try {
        await http.post(
          '/api/users',
          this.model,
          { 'Content-Type': 'multipart/form-data', Accept: 'application/json' },
        );
        this.$emit('auth-redirect');
      } catch (error) {
        this.error = error.response.data;
      }
    },
    getLanguage() {
      return getLanguage();
    },
    clearError() {
      // Technical debt: if there were multiple kinds of errors coming from the server for multiple
      // inputs, it wouldn't make sense to call this method from the email input. The idea is to
      // clear the error only when the user interacts with the specific input(s) that are
      // responsible. However, as long as there's only one error like this, we can get away with
      // clearing both error types on the email input, and the generic error for everything else.
      this.error = null;
    },
    clearOnlyGenericError() {
      if (this.error && !this.error.errors) {
        this.error = null;
      }
    },
    isValidEmail(email) {
      return checkEmailValidity(email);
    },
    handleAgreed(agreed) {
      this.model.agreed_to_agreement = agreed;
    },
  },
};
</script>

<style lang="scss" scoped>
  .field-name {
    display: flex;
    flex-direction: column;
    @media #{$screen-width-medium} {
      flex-direction: row;
      .field:first-child { margin-right: 1rem; }
    }
  }

  .privacy-policy {
    text-align: center;
    margin-top: 1rem;
  }

  .post-error {
    margin-top: 1rem;
  }

  .button {
    margin-top: 1rem;
    width: 100%;
  }

  .fade-enter-active, .fade-leave-active {
    transition: opacity 500ms;
  }

  .fade-enter, .fade-leave-to {
    opacity: 0
  }
  .password-validation {
    margin-bottom: 1.25rem;
    margin-top: -0.75rem;
    ul {
      li {
        line-height: 1.5rem;
        position: relative;
        padding-left: 1.5rem;
        color: $neg;
        font-size: 0.875rem;
        &:before {
          content: '';
          position: absolute;
          left: 0;
          top:0.35rem;
          height: 1rem;
          width: 1rem;
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' fill='%23"+ str-slice(#{$neg}, 2) + "'%3E%3Cpath d='M16,4c6.6,0,12,5.4,12,12s-5.4,12-12,12S4,22.6,4,16S9.4,4,16,4 M16,2C8.3,2,2,8.3,2,16s6.3,14,14,14s14-6.3,14-14 S23.7,2,16,2L16,2z'/%3E%3Crect x='15' y='7' width='2' height='12'/%3E%3Ccircle cx='16' cy='22.5' r='1.5'/%3E%3C/svg%3E");
        }
        &.valid {
          color: $neutral-700;
          &:before {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cpolygon points='11,27.4 1.3,17.7 2.7,16.3 11,24.6 29.3,6.3 30.7,7.7'/%3E%3C/svg%3E");
          }
        }
      }
    }
  }
</style>
