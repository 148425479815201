export function getCommonDashboardSigninUrlEn(process = window.process) {
  if (process && process.env && process.env.COMMON_DASHBOARD_SIGNIN_EN) {
    return process.env.COMMON_DASHBOARD_SIGNIN_EN;
  }
  return 'https://www.cifinancial.com/ci-di/ca/en/dashboard/login.html';
}

export function getCommonDashboardSigninUrlFr(process = window.process) {
  if (process && process.env && process.env.COMMON_DASHBOARD_SIGNIN_FR) {
    return process.env.COMMON_DASHBOARD_SIGNIN_FR;
  }
  return 'https://www.cifinancial.com/ci-di/ca/fr/dashboard/login.html';
}

export const CommonDashboardSigninUrls = {
  en: getCommonDashboardSigninUrlEn(window.process),
  fr: getCommonDashboardSigninUrlFr(window.process),
};
