<template>
  <error-wrapper>
    <p>
      <span v-t>We were unable to create your account at this time. If the problem persists, please contact</span>
      <a href="mailto:info@cidirectinvesting.com">info@cidirectinvesting.com</a>
      <span v-t>with your full name, email address used to sign up, and subject line outlining the problem.</span>
    </p>
  </error-wrapper>
</template>

<script>
import ErrorWrapper from './error-wrapper';

export default {
  components: {
    'error-wrapper': ErrorWrapper
  }
};
</script>