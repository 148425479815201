<template>
  <router-view :email="email" @reset-password="resetPassword($event)" />
</template>

<script>
export default {
  data() {
    return {
      email: '',
    };
  },
  methods: {
    resetPassword(email) {
      this.email = email;
      this.$router.push({ path: '/reset-password/check-email' });
    },
  },
};
</script>
