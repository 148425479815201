<template>
  <div class="page notranslate">
    <banner :link="{ title: bannerTitle, url:'/signin' }"></banner>
    <main class="container">
      <h3 v-t>This link is no longer valid.</h3>
      <p>
        <span v-t>Please contact your financial representative to get another invite. If you believe you received this in error, </span>
        <a v-t v-intercom>contact us</a>
      </p>
    </main>
  </div>
</template>
<script>
  import Intercom from '~vue/directives/intercom';
  import Banner from '@layoutTheme/banner';

  export default {
    directives: { Intercom },
    components: { Banner },
    computed: {
      bannerTitle() {
        return this.$t('Sign In');
      }
    }
  };
</script>
<style lang="scss" scoped>
  .page {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  .container {
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

</style>
