<template>
  <div class="notranslate">
    <p class="referral-agreement">
      <small>
        <span>Please read this</span>
        <a href="/referral-agreement-mcelvaine" target="_blank">referral disclosure statement</a>
        <span>carefully. 3P Financial, a division of McElvaine Investment Management is referring you, as a potential client, to CI Direct Investing, pursuant to a referral arrangement between CI Direct Investing and 3P Financial.</span>
      </small>
    </p>
    <p class="referral-agreement">
      <small>
        <span>In opening an account with CI Direct Investing, you will pay management fees to CI Direct Investing and will be placed into a model portfolio that was curated for prospective clients referred by 3P, as sub-adviser to CI Direct Investing and that may contain investment funds managed by 3P Financial. Please review the</span>
        <a href="https://help.cidirectinvesting.com/10762-general-questions/client-relationship-disclosure-information" target="_blank">Relationship Disclosure Information (RDI)</a>
        <span>of CI Direct Investing.</span>
      </small>
    </p>
    <div class="field-checkbox">
      <input id="agreement" v-model="agreedToAgreement" @change="setAgreed" type="checkbox" required/>
      <label for="agreement"><strong>By selecting “Agree and Continue” below, you acknowledge and agree to the information contained in this Referral Disclosure and the RDI of CI Direct Investing and wish to proceed to opening an account with CI Direct Investing.</strong></label>
    </div>
    <p class="referral-agreement">
      <small>
        <span>If you do not want to proceed you can still open an account with</span>
        <a href="https://www.cifinancial.com/ci-di/ca/en/invest.html" target="_blank">CI Direct Investing</a>;
        <span>however, by choosing to do so you will not be able to invest in the portfolios sub-advised by 3P Financial.</span>
      </small>
    </p>
  </div>
</template>

<script>
export default {
  data() {
    return {
      agreedToAgreement: false,
    };
  },
  methods: {
    setAgreed() {
      if (this.agreedToAgreement) {
        this.$emit('setAgreed', true);
      } else {
        this.$emit('setAgreed', false);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .referral-agreement {
    margin-top: 1rem;
  }
</style>
