/**
  * RFC 5322 Compliant Regex
  * https://stackoverflow.com/a/201378
  *
  # Limitations - This regex does not validate the following RFC 5322 standards correctly.
  #
  # Formats that validate as false (but should be true):
  # -- Space characters between quotes should be permitted: e.g. " "@example.org
  #
  # Formats that validate as true (but should be false):
  # -- Local-part longer than 64 characters should be prohibited: e.g. 1234567890123456789012345678901234567890123456789012345678901234+x@example.com
  */
// eslint-disable-next-line no-control-regex
const EMAIL_REGEX = /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/;

/**
 * Check if input is a valid email address format.
 *
 * @param {string} email Address string.
 * @returns {boolean} True if valid; false if not.
 */
export function checkEmailValidity(email) {
  return EMAIL_REGEX.test(email);
}
