<template>
  <div class="signup-page notranslate">
    <banner :link="link" />

    <main v-if="token" class="container notranslate">
      <div v-if="advisor" class="advisor-greeting">
        <img v-if="advisor.photoUrl" :class="{'advisor-photo':!isImageLogo,'advisor-logo':isImageLogo}" :alt="photoAlt" :src="advisor.photoUrl" />
        <span class="advisor-photo initial">
          {{ advisor.firstName.charAt(0) + advisor.lastName.charAt(0) }}
        </span>
        <span class="small">
          {{ signupIntro }}
        </span>
      </div>
      <div v-if="client" class="signup-content">
        <div class="signup">
          <div class="copy">
            <h2 v-t>Let's grow your money.</h2>
            <p>
              <span>
                <span>{{this.brandFullName}}</span> 
                <span v-t>will find the portfolio that’s right for you.</span> 
              </span>
              <span v-t>&nbsp;Already have an account?</span>
              <a v-t :href="link.url">Sign in</a>
            </p>
          </div>

          <signup-form :user="client" :partner-channel="advisor.partnerChannel" @auth-redirect="advisorLink" />
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import Banner from '@layoutTheme/banner';
import { http } from '~vue/helpers/http';
import SignupForm from '~vue/components/forms/form-signup';
import { capitalize } from '~vue/helpers/filters';
import { BRAND, BRAND_FULL_NAME } from '~vue/config/brand';
import { getSignupUrl } from '~utils/external_links';

export const deps = { http };

export default {
  components: {
    Banner,
    SignupForm,
  },
  filters: { capitalize },
  data() {
    return {
      link: {
        title: 'Sign In',
        url: 'representative-invitation/signin',
      },
      client: null,
      advisor: null,
      token: null,
      brand: BRAND,
      brandFullName: BRAND_FULL_NAME,
    };
  },
  computed: {
    signupIntro() {
      return this.$t('You are now considering opening an account with %1.', [this.brandFullName]);
    },
    photoAlt() {
      return this.$t('photo for %1 %2', [this.advisor.firstName, this.advisor.lastName]);
    },
    isImageLogo() { return this.advisor.photoUrl ? this.advisor.photoUrl.includes('advisor_logo') : false; },
  },
  created() {
    this.token = this.$route.query.token;

    if (this.token) {
      this.link.url = `representative-invitation/signin?token=${this.token}`;
      const endSessionPromise = deps.http.delete('/api/sessions');
      const fetchInvitePromise = deps.http.get(`/advisor_api/client_invitations/${this.token}`);

      Promise.all([endSessionPromise, fetchInvitePromise]).then(response => {
        const fetchInviteResponse = response[1];
        const client = fetchInviteResponse.data.client_invitations_data;
        const advisor = fetchInviteResponse.data.advisor_invitation_data;
        const accepted = client.accepted_at;
        const declined = client.declined_at;
        const { archived } = client;

        if (accepted != null || declined != null) {
          this.$router.push({ path: '/signin' });
        } else if (archived === true) {
          this.$router.push({ path: '/representative-invitation/invalid' });
        } else {
          this.client = {
            firstName: client.first_name,
            lastName: client.last_name,
            email: client.email,
          };
          this.advisor = {
            firstName: advisor.first_name,
            lastName: advisor.last_name,
            photoUrl: advisor.photo_url,
            partnerChannel: advisor.partner_channel,
          };
          this.trackAdvisorInvite();
        }
      }).catch(() => {
        this.$router.push({ path: '/representative-invitation/invalid' });
      });
    } else {
      window.location = getSignupUrl(this.$lang);
    }
  },
  methods: {
    async advisorLink() {
      try {
        const response = await deps.http.put('/advisor_api/link_invitation', { token: this.token });
        const returnToken = response.data.invitation_token;
        window.location = `/app/representative-link?token=${returnToken}`;
      } catch (error) {
        this.$router.push({ path: '/representative-invitation/invalid' });
      }
    },
    trackAdvisorInvite() {
      if (window.analytics && window.analytics.track) {
        window.analytics.track('advisor_invitation_clicked', {
          category: 'Advisor Invitation',
          label: 'Landed on Advisor Invition signup page',
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .signup-page {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  .container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
  }
  .advisor-greeting {
    padding: 2rem 0;
    border-bottom: 1px solid $neutral-100;
    margin-bottom: 2rem;
    display: flex;
    align-items: center;
    max-width: 39.75rem;
    @media #{$screen-width-medium} {
      border: none;
      margin: 0;
    }
  }
  .advisor-logo, .advisor-photo {
    flex: 0 0 auto;
    display: inline-block;
    margin-right: 1rem;
    vertical-align: middle;
    &.initial {
      background: $primary-300;
      color: $white;
      text-align: center;
      vertical-align: baseline;
      text-transform: uppercase;
      font-size: 3rem;
      line-height: 6rem;
    }
  }
  .advisor-logo {
    width: auto;
    height: auto;
    max-height: 7rem;
    max-width: 10rem;
    @media #{$screen-width-medium} {
      max-height: 10rem;
      max-width: 15rem
    }
  }
  .advisor-photo {
    width: 6rem;
    height: 6rem;
    border-radius: 50%;
  }
  .signup-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;

    @media #{$screen-width-medium} {
      width: 39.75rem;
      border-top: 1px solid $neutral-100;
    }

    .signup {
      max-width: 20rem;
    }
  }
  .language-hide { display: none; }
</style>
