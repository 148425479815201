/* istanbul ignore next */
import OktaSignIn from '@okta/okta-signin-widget';
import { OktaSettings } from '~vue/config/okta-widget';

/* istanbul ignore next */
export function getOktaSignIn(processCreds, currentLang = 'en', i18n = { en: {}, fr: {} }) {
  return new OktaSignIn({
    baseUrl: OktaSettings.domain,
    clientId: OktaSettings.clientId,
    redirectUri: OktaSettings.redirectUri,
    language: currentLang,
    defaultCountryCode: 'CA',
    authParams: {
      pkce: true,
      issuer: OktaSettings.domain,
      display: 'page',
      scopes: OktaSettings.scopes,
    },
    processCreds,
    i18n,
  });
}
