export function getBrand(process = window.process) {
  if (process && process.env) {
    return process.env.WHITELABEL_BRAND;
  }
  return 'wealthbar';
}

export const BRAND = getBrand(window.process);

export function getBrandFullName(brand) {
  if (brand === 'assante') {
    return 'Assante Connect';
  }
  return 'CI Direct Investing';
}

export const BRAND_FULL_NAME = getBrandFullName(BRAND);
