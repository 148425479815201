<template>
  <div class="page notranslate">
    <banner />
    <main>
      <div class="content">
        <img class="envelop" src="@theme/images/auth/email.svg" />
        <h2 v-t>Check your email</h2>
        <span v-if="subHeading !== ''">{{ subHeading }}</span>
        <p class="small">
          <span v-t>Didn't get the email? Ensure you've entered the correct email address and check your spam folder, or contact support at</span>
          <template v-if="brand === 'assante'">
            <a href="tel:18889227366">1-888-922-7366</a>.
          </template>
          <template v-else>
            <a href="tel:18773101088">1-877-310-1088</a>.
          </template>
        </p>
        <div class="signin-block"><a v-t href="/signin" class="return-to-signin">Back to sign in</a></div>
      </div>
    </main>
  </div>
</template>

<script>
import Banner from '@layoutTheme/banner';
import { BRAND } from '~vue/config/brand';

export default {
  components: {
    Banner,
  },
  props: {
    email: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      brand: BRAND,
    };
  },
  computed: {
    subHeading() {
      if (this.email) {
        return this.$t(
          "We've sent an email to %1.",
          [this.email],
        );
      }
      return '';
    },
  },
};
</script>

<style lang="scss" scoped>
  .page {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    align-items: center;
  }

  main {
    flex: 1 0 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 3rem;
    text-align: center;
    h2 { margin: 1rem 0; }
    p {
      color: $neutral-500;
      margin: 2rem 0 1.75rem;
      max-width: $small-width;
    }
  }

  .envelop {
    width: 3rem;
    height: 3rem;
  }
  .signin-block {
    text-align: center;

    .return-to-signin {
      color: #777;
      text-decoration: none;
      font-size: 0.8125rem;
      &:hover, &:active {
        text-decoration: underline;
      }
    }
  }
</style>
