import { render, staticRenderFns } from "./horizons-agreement.vue?vue&type=template&id=b80e18ae&scoped=true&"
import script from "./horizons-agreement.vue?vue&type=script&lang=js&"
export * from "./horizons-agreement.vue?vue&type=script&lang=js&"
import style0 from "./horizons-agreement.vue?vue&type=style&index=0&id=b80e18ae&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b80e18ae",
  null
  
)

export default component.exports