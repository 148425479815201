import Locale from 'utils/locale';

export function getLocaleBuilder(getLanguage) {
  return () => {
    if (getLanguage() === 'french') {
      return 'fr-CA';
    }
    return 'en-CA';
  };
}
export const getLocale = getLocaleBuilder(Locale.getLanguage);

export function localeCurrency(amount, options = {}) {
  const settings = {
    decimals: 2,
    zero: null,
  };
  let processedAmount = amount;

  Object.assign(settings, options);
  if (processedAmount == null) processedAmount = 0;
  if ((+processedAmount === 0 && settings.zero)) return settings.zero;
  const amountInteger = +processedAmount;
  const currencyStyle = { style: 'currency', currency: 'CAD', minimumFractionDigits: settings.decimals };
  const currency = amountInteger.toLocaleString(getLocale(), currencyStyle);
  return currency;
}

export default {
  getLocale,
  localeCurrency,
};
