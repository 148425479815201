<template>
  <div class="page notranslate">
    <banner></banner>
    <main>
      <div class="content">
        <img class="mobile" src="@theme/images/auth/2fa.png">
        <h2 class="title" v-if="isAuthenticator" v-t>Check your Authenticator app</h2>
        <h2 class="title" v-else v-t>Check your phone</h2>
        <p v-if="isAuthenticator" v-t>Enter the 6 digit code.</p>
        <span v-else>
          <p v-t>Enter the 6 digit code we sent you to your device via SMS.</p>
          <p v-t>If you are not in Canada or you use a non-Canadian phone number you might not receive the code. Please switch to the Authenticator App or contact Client Services.</p>
        </span>
        <vue-form :state="formstate" @submit.prevent="sendToken" class="form">
          <validate auto-label>
            <label for="token" v-t>Code</label>
            <input type="text" id="token" v-model="token" name="token" maxlength="6" v-focus="true" :class="{ invalid: errorMessage || (formstate.$invalid && (formstate.$touched || formstate.$submitted)) }" @input="clearError" required>
            <field-messages name="token" show="$touched || $submitted" class="error-message">
              <span slot="required">{{ $t('Required') }}</span>
              <transition name="fade">
                <p class="error-message post-error" v-if="errorMessage">{{ errorMessage }}</p>
              </transition>
            </field-messages>
          </validate>
          <div v-if="!isResetPassword" class="field-checkbox">
            <input type="checkbox" id="remember-device" v-model="rememberDevice">
            <label for="remember-device" v-t>Don't ask for codes on this device</label>
          </div>
          <div class="submit-container">
            <button v-if="state !== 'loading'" type="submit" class="full" v-t>Submit</button>
            <div class="loader" v-if="state === 'loading'">
              <loading-spinner size="1rem"></loading-spinner>
              <span v-t>Sending...</span>
            </div>
          </div>
        </vue-form>
        <p v-if="!isAuthenticator && !errorMaxRetries" class="resend-text">
          <span v-t>Didn't get the text?&nbsp;</span>
          <button @click="resendText" v-t>Resend code</button>
        </p>
        <div v-if="errorMaxRetries && errorMaxRetries !== ' '">
          <transition name="fade">
            <p class="error-message post-error">{{ errorMaxRetries }}</p>
          </transition>
        </div>
        <div class="resend-text-container">
          <div v-if="resendTextState === 'resent'"><span v-t>Code sent!</span></div>
          <div class="loader" v-if="resendTextState === 'resending'">
            <loading-spinner size="25px"></loading-spinner>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import Banner from '@layoutTheme/banner';
import VueForm from 'vue-form';
import { http } from '~vue/helpers/http';
import LoadingSpinner from '~vue/components/loading-spinner';
import InputFocus from '~vue/directives/input-focus';

export default {
  mixins: [VueForm],
  components: {
    Banner,
    LoadingSpinner,
  },
  data() {
    return {
      formstate: {},
      token: '',
      rememberDevice: false,
      state: '',
      resendTextState: '',
      errorMessage: null,
      /*
       this is set to an empty string on purpose - to prevent the error from showing as well as the resend code link,
       when the page first loads. After initial load it's either empty or has an error message
       */
      errorMaxRetries: ' ',
      errorResendTimer: null,
    };
  },
  directives: {
    focus: InputFocus,
  },
  computed: {
    isAuthenticator() {
      return this.$route.query.method ? this.$route.query.method === 'authenticator' : false;
    },
    isResetPassword() {
      return !!this.$route.query.token;
    },
    hasPartnerChannel() {
      return this.$route.query.partner_channel;
    },
  },
  mounted() {
    this.resendText();
  },
  methods: {
    async sendToken() {
      if (this.formstate.$invalid || this.state === 'loading') return;
      if (this.errorMessage) this.errorMessage = null;

      this.state = 'loading';

      if (this.isResetPassword) {
        try {
          await http.put('/api/two-factor-auth/reset-password', { token: this.token });
          this.$router.push({ path: '/reset-password/new-password', query: { token: this.$route.query.token } });
        } catch (error) {
          this.errorMessage = error.response.data.message;
          this.state = null;
        }
      } else {
        try {
          const response = await http.put(
            '/api/two_factor_auth',
            {
              token: this.token,
              remember_device: this.rememberDevice,
              partner_channel: this.$route.query.partner_channel,
            },
          );
          if (response.data && response.data.advisor_redirect_url) {
            window.location = response.data.advisor_redirect_url;
          } else {
            window.location = '/signin/continue';
          }
        } catch (error) {
          this.state = null;
          this.errorMessage = error.response.data.message;
        }
      }
    },
    async resendText() {
      this.resendTextState = 'resending';
      try {
        await http.get('/api/two_factor_auth');
        this.resendTextState = 'resent';
        this.clearResendError();
      } catch (error) {
        this.state = null;
        if (error.response.data.error_code && error.response.data.error_code === 'max_retries_reached') {
          this.errorMaxRetries = error.response.data.message;
          this.resendTextState = null;
          this.resetSendError();
        } else {
          this.errorMessage = this.$t(error.response.data.message);
        }
      }
    },
    resetSendError() {
      this.errorResendTimer = setTimeout(() => this.clearResendError(), 120000);
    },
    clearError() {
      this.errorMessage = null;
    },
    clearResendError() {
      this.errorMaxRetries = '';
    },
  },
};
</script>

<style lang="scss" scoped>
  .page {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    align-items: center;
  }

  main {
    flex: 1 0 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
  }

  .content {
    display: flex;
    width: 85%;
    max-width: 21rem;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 3rem 0;
    text-align: center;
    h2 { margin: 1rem 0; }
  }

  .mobile {
    height: 4rem;
  }

  .form {
    width: 100%;
    text-align: left;

    button { margin: 1rem 0; }

    .loader {
      padding: 1rem 0;
      text-align: center;
    }
  }

  .resend-text {
    margin-top: 1rem;
    color: $neutral-500;
  }

  .resend-text-container {
    margin-top: 0.5rem;
  }

</style>
