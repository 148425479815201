function minLength(password) {
  return password.trim().length > 7;
}

function hasNumber(password) {
  return /\d/.test(password);
}

function hasSymbol(password) {
  return /\W|_/g.test(password);
}

function hasLowerCase(password) {
  return /^.*[a-z].*$/.test(password);
}

function hasUpperCase(password) {
  return /^.*[A-Z].*$/.test(password);
}

function containsWord(strToSearch, strPattern) {
  if (strToSearch.length < 3) return false;
  const lcStrToSearch = strToSearch.toLowerCase();
  const lcStrPattern = strPattern.toLowerCase();
  return lcStrToSearch.indexOf(lcStrPattern) !== -1;
}

function hasSubstrings(strToSearch, strPattern) {
  if (!strToSearch || !strPattern || strToSearch.length < 3 || strPattern.length < 3) return false;
  const arrPattern = strPattern.split(/[\-,_,@,\,,\.]/g);
  return arrPattern.some((val) => {
    if (val.length < 3) return false;
    return containsWord(strToSearch, val);
  });
}

function containsEmail(password, email) {
  if (!email || email.length === 0) return false;
  const trimmedEmail = email.trim();
  const emailParts = trimmedEmail.split('@');
  const domainParts = emailParts[1] ? emailParts[1].split('.') : [''];
  return hasSubstrings(password, emailParts[0]) || hasSubstrings(password, domainParts[0]);
}

function containsName(password, name) {
  if (!name || name.length === 0) return false;
  const trimmedName = name.trim();
  if (trimmedName.length === 0) return false;
  return hasSubstrings(password, trimmedName);
}

function isValidPassword(password = '', email = '', firstname = '', lastname = '') {
  return minLength(password)
    && hasNumber(password)
    && hasSymbol(password)
    && hasLowerCase(password)
    && hasUpperCase(password)
    && !containsEmail(password, email)
    && !containsName(password, firstname)
    && !containsName(password, lastname);
}

function getPasswordValidity(password = '', email = '', firstname = '', lastname = '') {
  return {
    minLength: minLength(password),
    number: hasNumber(password),
    symbol: hasSymbol(password),
    lowerCase: hasLowerCase(password),
    upperCase: hasUpperCase(password),
    email: !containsEmail(password, email),
    firstName: !containsName(password, firstname),
    lastName: !containsName(password, lastname),
  };
}

export { isValidPassword, getPasswordValidity, containsWord };
