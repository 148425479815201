import intercom from 'utils/intercom.ts';

const desktopOnClickCtor = (intercom, value) => {
  return () => {
    value.startsWith('show') ? intercom.show(value) : intercom.execute(value);
  }
}

export function intercomDirectiveCtor(intercom, window, desktopOnClick = desktopOnClickCtor) {
  return function inserted(el, { value = 'show' }, vnode) {
    const {
      meta,
      query
    } = vnode.context.$route;

    if (query.device === 'mobile' || meta.device === 'mobile') {
      el.onclick = () => window.location = 'wealthbar://contactus';
    } else {
      const onclick = desktopOnClick(intercom, value);
      el.onclick = onclick;
      el.onkeyup = (event) => { if (event.keyCode === 13 || event.keyCode === 32) { onclick(); } }
    }
  }
}

export default intercomDirectiveCtor(intercom, window);
