import Storage from './storage';
import intercom from './intercom';
import changeLanguage from '@wealthbar/i18n/dist/change_language';

let currentLanguage = null;

export function getLocale() {
  const language = document.documentElement.lang ||
                       window.navigator.language ||
                   window.navigator.userLanguage;
  return language.slice(0, 2) === 'fr' ? 'fr-CA' : 'en-US';
}

export function getLanguage() {
  if (currentLanguage) {
    return currentLanguage;
  }
  const match = window.location.search.match(/\blang=([^&#]*)\b/);
  const langUrl = !match ? null : match[1].toLowerCase();
  const langStorage = Storage.getItem('language');
  if (langUrl === 'fr' || langUrl === 'fr-ca') {
    currentLanguage = 'french';
  } else if (langUrl === 'en') {
    currentLanguage = 'english';
  } else if (langStorage) {
    currentLanguage = langStorage;
  } else {
    currentLanguage = getLocale() === 'fr-CA' ? 'french' : 'english';
  }
  Storage.setItem('language', currentLanguage);
  return currentLanguage;
}

export function setLanguage(language) {
  const hasLang = window.location.search.match(/\blang=/);

  if (language === 'french') {
    Storage.setItem('language', 'french');
    currentLanguage = 'french';
    window.Transifex.live.onReady(() => {
      window.Transifex.live.translateTo('fr_CA', true);
    });
    intercom.execute('update', { 
      preferred_language: 'French',
      language_override: 'fr' 
    });
    changeLanguage('fr-CA');

    if (hasLang) {
      window.location.search = window.location.search.replace(/lang=en/, 'lang=fr-ca');
    }
  } else {
    Storage.setItem('language', 'english');
    currentLanguage = 'english';

    window.Transifex.live.onReady(() => {
      window.Transifex.live.translateTo('en', true);
    });
    intercom.execute('update', { 
      preferred_language: 'English',
      language_override: 'en' 
    });
    changeLanguage('en');

    if (hasLang) {
      window.location.search = window.location.search.replace(/lang=fr(?:-ca)?/, 'lang=en');
    }
  }
}

export function isFrench() {
  return (getLanguage() === 'french');
}

export default {
  getLocale,
  getLanguage,
  setLanguage,
  isFrench,
};
